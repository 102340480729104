<template>
  <div class="app-container">
    <upload-excel-component
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
    />
    <div
      v-if="tableData.length>0"
      class="d-flex justify-content-center"
    >
      <b-button
        block
        :loading="isLoading"
        type="primary"
        @click="handleUpload"
      >
        Confirmer
      </b-button>
    </div>
    <b-table-lite
      style="font-size: 22px; border: 2px solid black"
      responsive
      :items="tableData"
      :fields="tableHeader"
    >
      <template #cell(taskDescription)="data">
        <b-card-text
          class="font-weight-bold mb-25"
          style="font-size: 25px; border: 2px solid black"
        >
          {{ data.item.taskTitle }}
        </b-card-text>
        <b-card-text class="text-nowrap">
          {{ data.item.taskDescription }}
        </b-card-text>
      </template>
    </b-table-lite>
  </div>
</template>

<script>

import {
  BTableLite, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UploadExcelComponent from './UploadExel.vue'

export default {
  name: 'UploadExcel',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UploadExcelComponent, BTableLite, BButton, ToastificationContent,
  },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      isLoading: false,

    }
  },
  methods: {
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async handleUpload() {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux modifier le stock labo ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = true
        if (result.value) {
          axios
            .post('/products/import-labo/', { products: this.tableData })
            .then(() => {
              this.isLoading = false
              setTimeout(() => {
                this.showToast(
                  'success',
                  'top-center',
                  'Stock labo modifié avec succés',
                )
              }, 1000)
            })
            .catch(error => {
              this.isLoading = false
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.response.data)
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.isLoading = false
          this.$swal({
            title: 'Fermer',
            text: 'Action Annuler :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning',
      })
      return false
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      this.tableHeader = header
    },
  },
}
</script>
